import React, { useContext, useState } from "react";
import { AuthenticationContext } from "../../../../services/authentication/authentication.context";
import PulseLoader from "react-spinners/PulseLoader";

export const SettingsScreen = ({ setParentState }) => {
  const { onResetPassword, onLogout } = useContext(AuthenticationContext);
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validatePassword = () => {
    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$&()`.+,\/"-])/gm;

    if (password !== repeatedPassword) {
      setError("Please ensure the password and repeated password match.");
      return false;
    }
    if (password.length < 14) {
      setError("Please ensure the password is at least 14 characters long.");
      return false;
    }
    if (password.length > 64) {
      setError("Please ensure the password is no longer than 64 characters.");
      return false;
    }
    if (!regex.test(password)) {
      setError(
        "Please ensure that the password contains at least one uppercase letter, one lowercase letter, one number and one symbol."
      );
      return false;
    }
    setError("");
    return true;
  };

  return (
    <div id="viewConfessionsContainer">
      <div id="viewConfessionsBanner">
        <h1>Settings</h1>
        <select
          onChange={(event) => {
            setParentState(event.target.value);
          }}
        >
          <option value={0}>Reply to Confessions</option>
          <option value={1}>Settings</option>
        </select>
      </div>
      <div id="resetPasswordContainer">
        <p>Reset Password</p>
        <label htmlFor="password">New Password</label>
        <br />
        <input
          type="password"
          id="password"
          name="password"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
        <br />
        <br />
        <label htmlFor="repeatedDassword">Re-enter New Password</label>
        <br />
        <input
          type="password"
          id="repeatedPassword"
          name="repeatedPassword"
          onChange={(event) => {
            setRepeatedPassword(event.target.value);
          }}
        />
        <br />
        <p style={{ fontStyle: "italic", color: "red" }}>{error}</p>
        {!isLoading ? (
          <button
            onClick={() => {
              if (validatePassword()) {
                setIsLoading(true);
                onResetPassword(password)
                  .then(() => {
                    setIsLoading(false);
                    window.alert("Your password has successfully been reset.");
                  })
                  .catch(() => {
                    setIsLoading(false);
                    setError("There was a problem updating the password.");
                  });
              }
            }}
          >
            Reset Password
          </button>
        ) : (
          <PulseLoader
            color={"#295927"}
            loading={isLoading}
            size={20}
            speedMultiplier={0.5}
          />
        )}
      </div>
    </div>
  );
};
