import React, { useState, useContext } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import { Header } from "../../header/Header.component";
import { Link } from "react-router-dom";

export const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { onLogin, error } = useContext(AuthenticationContext);

  const validateEmailAndPassword = () => {
    const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );
    if (password.length && validEmail) {
      setErr("");
      return true;
    } else if (!email.length && !password.length) {
      setErr("Please enter your email and password");
    } else if (!password.length) {
      setErr("Please enter your password");
    } else {
      setErr("Please enter a valid email address");
    }
    return false;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (validateEmailAndPassword()) {
      setIsLoading(true);
      onLogin(email, password);
    }
  };

  return (
    <>
      <Header />
      <div className="authScreenContainer">
        <form className="authForm">
          <h3>Login</h3>
          <label htmlFor="email">Email:</label>
          <br />
          <input
            type="email"
            id="email"
            name="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <br />
          <div className="spacer"></div>
          <label htmlFor="password">Password:</label>
          <br />
          <input
            type="password"
            id="password"
            name="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <br />
          <p style={{ fontStyle: "italic", color: "red" }}>
            {error
              ? "There was a problem logging in."
              : err
              ? "Please enter a valid email address and password"
              : ""}
          </p>
          {!isLoading || error ? (
            <button className="authButton" onClick={onSubmit}>
              Login
            </button>
          ) : (
            <PulseLoader
              color={"#295927"}
              loading={isLoading}
              size={10}
              speedMultiplier={0.5}
            />
          )}
          <br />
          <br />
          <Link to="../forgot-password" className="link">Forgot password?</Link>
        </form>
      </div>
    </>
  );
};
