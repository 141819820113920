import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export const Header = () => {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <header id="header">
      <h1>OldCatholicConfessions.net</h1>
      <div className="dropdown">
        <button
          className="dropbtn"
          onClick={() => {
            setShowDropDown(!showDropDown);
          }}
        >
          <FontAwesomeIcon icon={faBars} size="2x" />
        </button>
        <div
          className={
            showDropDown
              ? "dropdown-content-visible"
              : "dropdown-content-hidden"
          }
        >
          <Link
            to="/login"
            onClick={() => {
              setShowDropDown(false);
            }}
          >
            Login
          </Link>
          <Link
            to="/"
            onClick={() => {
              setShowDropDown(false);
            }}
          >
            Make a Confession
          </Link>
        </div>
      </div>
    </header>
  );
};
