// const apiBaseUrl = "http://localhost:8081";
const apiBaseUrl = "https://www.oldcatholicconfessions.net";


//get function used in checkForUser and login
const getUser = (token, email) => {
  return new Promise((resolve, reject) => {
    if (token && email) {
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get(`${apiBaseUrl}/api/user/${email}`)
        .then((res) => {
          const user = res.data.data;

          //return the user
          resolve(user);
        })
        .catch((e) => {
          reject("User could not be found.");
        });
    } else resolve(null);
  });
};

//make a post request to authenticate the user, then run getUser and return the user
export const loginRequest = (email, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiBaseUrl}/api/login`, { email, password })
      .then(async (res) => {
        try {
          const { token } = res.data;
          let user = await getUser(token, email);
          localStorage.setItem("jwtToken", token);
          resolve(user);
        } catch (e) {
          reject(e);
        }
      })
      .catch((e) => {
        reject(
          "Authentication failed. Please check that your email and password are entered correctly."
        );
      });
  });
};

//check for user runs on authentication context mount
export const checkForUser = () => {
  return new Promise(async (resolve, reject) => {
    //get the token from localStorage
    const token = localStorage.getItem("jwtToken");

    //if there is a jwt in localstorage, look the user up in the database and return the user
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .post(`${apiBaseUrl}/api/verify`)
        .then((res) => {
          const user = res.data.data;
          resolve(user);
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};

export const loadConfessions = () => {
  return new Promise(async (resolve, reject) => {
    //get the token from localStorage
    const token = localStorage.getItem("jwtToken");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get(`${apiBaseUrl}/api/confessions`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    }
  });
};

export const updatePenance = (confessionId, penance) => {
  return new Promise(async (resolve, reject) => {
    //get the token from localStorage
    const token = localStorage.getItem("jwtToken");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .put(`${apiBaseUrl}/api/penances`, { confessionId, penance })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    }
  });
};

export const requestPasswordReset = (email) => {
  return new Promise(async (resolve, reject) => {
    axios.post(`${apiBaseUrl}/api/request-password-reset`, {email}).then(() => {
      resolve();
    }).catch((e) => {
      reject(e);
    });
  });
}

export const resetPasswordLoggedOut = (email, resetCode, tempPassword, newPassword) => {
  return new Promise(async (resolve, reject) => {
    console.log("resetPassword reached");
    axios.put(`${apiBaseUrl}/api/reset-password-logged-out`, {email, resetCode, tempPassword, newPassword}).then(() => {
      resolve();
    }).catch((e) => {
      reject(e);
    });
  })
}

export const resetPassword = (newPassword) => {
  return new Promise(async (resolve, reject) => {
    //get the token from localStorage
    const token = localStorage.getItem("jwtToken");

    //if there is a jwt in localstorage, look the user up in the database and return the user
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .post(`${apiBaseUrl}/api/verify`)
        .then((_) => {
          //once the user is verified, their password can be reset.
          axios
            .put(`${apiBaseUrl}/api/users`, { newPassword })
            .then((_) => {
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};
