import React from "react";

export const PenanceView = ({ message, penance }) => {
  return (
    <form id="confessionResponseForm">
      <h2>Bless me, Father, for I have sinned:</h2>
      <p>{message}</p>
      <label htmlFor="penance">Penance</label>
      <br />
      <p>{penance}</p>
    </form>
  );
};
