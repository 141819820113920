import React, { useContext } from "react";
import { AuthenticationContext } from "../../services/authentication/authentication.context";
import { LoginScreen } from "../../features/accounts/screens/Login.screen";
import { BishopDashboard } from "../../features/accounts/screens/bishop-dashboard/BishopDashboard.screen";

export const AccountsNav = () => {
  const { isAuthenticated, user } = useContext(AuthenticationContext);

  if (isAuthenticated) {
    switch (user.type) {
      case 0:
        return <AdminDashboard />;
      case 1:
        return <ClergyDashboard />;
      case 2:
        return <BishopDashboard />;
      default:
        return <AdminDashboard />;
    }
  } else return <LoginScreen />;
};

const AdminDashboard = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Admin User</h1>
    </div>
  );
};

const ClergyDashboard = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Clergy User</h1>
    </div>
  );
};
