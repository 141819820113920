import React, { useState } from "react";
import { Disclaimer } from "../components/Disclaimer.component";
import { ConfessionForm } from "../components/ConfessionForm.component";
import { FormSubmitSuccess } from "../components/FormSubmitSuccess.component";
import { Header } from "../../header/Header.component";

export const ConfessionScreen = () => {
  const [pageState, setPageState] = useState(0);
  return (
    <>
      <Header />
      <div id="confessionScreenContainer">
        {(() => {
          switch (pageState) {
            case 0:
              return (
                <div
                  id="enterConfessional"
                  onClick={() => {
                    setPageState(0.5);
                  }}
                >
                  <span>Enter a Confessional</span>
                </div>
              );
            case 0.5:
              return <Disclaimer setParentState={setPageState} />;
            case 1:
              return <ConfessionForm setParentState={setPageState} />;
            case 2:
              return <FormSubmitSuccess setParentState={setPageState} />;
            default:
              return (
                <div id="enterConfessional">
                  <span>Click to enter the confessional</span>
                </div>
              );
          }
        })()}
      </div>
    </>
  );
};
