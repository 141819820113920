import React from "react";

export const FormSubmitSuccess = ({ setParentState }) => {
  return (
    <div id="confessionFormContainer">
      <div id="confessionForm">
        <h2>Thank you for your confession.</h2>
        <h3>Please say the prayer of contrition:</h3>
        <p>
          <i>
            Oh my God, I am heartily sorry for having offended thee, and I
            detest all my sins because they offend thee, my God who art all Good
            and deserving of all my love. I firmly resolve With the help of Thy
            grace to amend my life, do penance and avoid the near of occasion of
            sin. Amen.
          </i>
        </p>
        <hr />
        <p>
          <small>
            You will receive a notification via email once your penance is
            ready. For privacy reasons, confessions and penances will be deleted{" "}
            <strong>immediately</strong> after you have viewed them,{" "}
            <strong>or</strong> 24 hours after they have been issued.
          </small>
        </p>
        <button
          onClick={() => {
            setParentState(0);
          }}
        >
          I have said the prayer of contrition and I will check my email for my
          penance.
        </button>
      </div>
    </div>
  );
};
