// const apiBaseUrl = "http://localhost:8081";
const apiBaseUrl = "https://www.oldcatholicconfessions.net";

export const createConfessionRequest = (originatingEmail, message) => {
  return new Promise((resolve, reject) => {
    console.log("CREATING CONFESSION REQUEST")
    axios
      .post(apiBaseUrl + "/api/confessions", { originatingEmail, message })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const viewPenanceRequest = (cnfs, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post(apiBaseUrl + "/api/penances?cnfs=" + cnfs, { password })
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((e) => {
        console.log("error " + e);
        reject(e);
      });
  });
};

export const deleteConfession = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(apiBaseUrl + "/api/confessions", { data: { id } })
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};
