import React, {useState, useContext} from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { AuthenticationContext } from '../../../services/authentication/authentication.context';
import { Header } from '../../header/Header.component';
import { Link } from 'react-router-dom';

export const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const {error : serverError, onRequestPasswordReset} = useContext(AuthenticationContext);

  const onSubmit = (event) => {
    event.preventDefault();
    setErr("");
    if(!email.length) {
        setErr("Please enter an email address.");
        return;
    }
    const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        email
    );
    if(!validEmail) {
        setErr("Please enter a valid email address.");
        return;
    }
    setIsLoading(true);
    onRequestPasswordReset(email).then(() => {
        setIsLoading(false);
        setResetEmailSent(true);
    }).catch((e) => {
        console.log(e);
        setIsLoading(false);
    });
  }

  return (
    <>
      <Header />
      <div className="authScreenContainer">
        <form className="authForm">
          <h3>Request Password Reset</h3>
          {!resetEmailSent ? (
            <>
              <label htmlFor="email">Email Address</label>
              <br />
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <br />
              <p style={{ fontStyle: "italic", color: "red" }}>
                {serverError
                  ? "There was a problem sending the password reset email."
                  : err}
              </p>
              {!isLoading || serverError ? (
                <button className="authButton authButtonSm" onClick={onSubmit}>
                  Send Password Reset Email
                </button>
              ) : (
                <PulseLoader
                  color={"#295927"}
                  loading={isLoading}
                  size={10}
                  speedMultiplier={0.5}
                />
              )}
            <br />
            </>
          ) :
          (
            <>
            <p>Reset email successfully sent.</p>
            </>
          )}
          <br />
          <Link to="/login" className='link'>Back to Login</Link>
        </form>
      </div>
    </>
  )
}