import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { AuthenticationContext } from "../../../../services/authentication/authentication.context";
import { ConfessionResponseForm } from "../../components/ConfessionResponseForm.component";
import { PenanceView } from "../../components/PenanceView.component";
import PulseLoader from "react-spinners/PulseLoader";

export const ViewConfessions = ({ setParentState }) => {
  const { confessions, isLoading } = useContext(AuthenticationContext);

  const [selectedOption, setSelectedOption] = useState("new");
  const [selectedMessage, setSelectedMessage] = useState("none");
  const [selectedConfessionId, setSelectedConfessionId] = useState("");
  const [rerenders, setRerenders] = useState(0);

  return (
    <div id="viewConfessionsContainer">
      <div id="viewConfessionsBanner">
        <h1>Reply to Confessions</h1>
        <select
          onChange={(event) => {
            setParentState(event.target.value);
          }}
        >
          <option value={0}>Reply to Confessions</option>
          <option value={1}>Settings</option>
        </select>
      </div>
      <div id="innerContainer">
        <div id="leftFlexItem">
          <div id="viewConfessionsBreadcrumb">
            <span
              className={selectedOption === "new" ? "selected" : ""}
              onClick={() => {
                setSelectedMessage("none");
                setSelectedOption("new");
                setSelectedConfessionId("");
              }}
            >
              New Confessions
            </span>
            <span>{" / "}</span>
            <span
              className={selectedOption === "answered" ? "selected" : ""}
              onClick={() => {
                setSelectedMessage("none");
                setSelectedOption("answered");
                setSelectedConfessionId("");
              }}
            >
              Answered Confessions
            </span>
          </div>
          <div>
            <ul id="viewConfessionsUl">
              {!confessions.length && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PulseLoader
                    color={"#295927"}
                    loading={isLoading}
                    size={20}
                    speedMultiplier={0.5}
                  />
                </div>
              )}
              {confessions.map((c, index) => {
                //if the confession has been responded to and new is selected, don't render an li
                if (selectedOption === "new" && c.respondedTo == true) return;
                //if the confession hasn't been answered and answered is selected, don't render an li
                if (selectedOption === "answered" && c.respondedTo == false)
                  return;

                //otherwise render the li
                let date = new Date(c.createdAt);
                date = date.toString().split(" ").slice(0, 4).join(" ");
                return (
                  <li
                    key={index.toString()}
                    onClick={() => {
                      setSelectedMessage(index);
                      setSelectedConfessionId(c.id);
                    }}
                    className={selectedMessage === index ? "selected" : ""}
                  >
                    <small>{date}</small>
                    <p>
                      {"Bless me, Father, for I have sinned: " +
                        c.message.slice(0, 20) +
                        "..."}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div id="rightFlexItem">
          {selectedOption === "new" ? (
            <ConfessionResponseForm
              message={
                selectedMessage !== "none"
                  ? confessions[selectedMessage].message
                  : ""
              }
              id={selectedConfessionId}
              parentRerenders={rerenders}
              setParentMessage={setSelectedMessage}
              setParentId={setSelectedConfessionId}
            />
          ) : (
            <PenanceView
              message={
                selectedMessage !== "none"
                  ? confessions[selectedMessage].message
                  : ""
              }
              penance={
                selectedMessage !== "none"
                  ? confessions[selectedMessage].penance
                  : ""
              }
            />
          )}
        </div>
      </div>
      <div id="mobileStackContainer">
        <div
          id="stack"
          className={selectedMessage === "none" ? "hide" : "show"}
        >
          <div id="backButtonRow">
            <button
              onClick={() => {
                setSelectedMessage("none");
              }}
              id="backButton"
            >
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
          </div>
          {selectedOption === "new" ? (
            <ConfessionResponseForm
              message={
                selectedMessage !== "none"
                  ? confessions[selectedMessage].message
                  : ""
              }
              id={selectedConfessionId}
              parentRerenders={rerenders}
              setParentRerenders={setRerenders}
              setParentMessage={setSelectedMessage}
              setParentId={setSelectedConfessionId}
            />
          ) : (
            <PenanceView
              message={
                selectedMessage !== "none"
                  ? confessions[selectedMessage].message
                  : ""
              }
              penance={
                selectedMessage !== "none"
                  ? confessions[selectedMessage].penance
                  : ""
              }
            />
          )}
          <div className="spacer">
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};
