import React from "react";

export const Disclaimer = ({ setParentState }) => {
  return (
    <div id="confessionFormContainer">
      <div id="confessionForm">
        <h2>Your Confession is About to Begin</h2>
        <h3>Please note that:</h3>
        <p>
          <strong>
            Email addresses are encrypted, and are not visible to any clergy
            members.
          </strong>{" "}
          Email addresses are required for the sole purpose of sending you an
          automated notification once your penance is ready.
        </p>
        <p>
          <strong>
            Once you receive an email with a link to your penance, you will have
            24 hours to view it.
          </strong>{" "}
          Once you have viewed it, or after 24 hours have passed, it will be
          deleted.
        </p>
        <p>
          <strong>
            Each user may only make up to two confessions per 24-hour period.
          </strong>
        </p>
        <button
          id="submitConfession"
          onClick={() => {
            setParentState(1);
          }}
        >
          Begin Confession
        </button>
      </div>
    </div>
  );
};
