import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthenticationContextProvider } from "./services/authentication/authentication.context";
import { AccountsNav } from "./infrastructure/navigation/accounts.navigator";
import { ConfessionScreen } from "./features/confessions/screens/Confession.screen";
import { ViewMyPenance } from "./features/penances/ViewMyPenance.screen";
import { ForgotPasswordScreen } from "./features/accounts/screens/ForgotPassword.screen";
import { PasswordResetScreen } from "./features/accounts/screens/PasswordReset.screen"

function App() {
  return (
    <AuthenticationContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ConfessionScreen />} />
          <Route path="/login" element={<AccountsNav />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/password-reset" element={<PasswordResetScreen />} />
          <Route path="/penances" element={<ViewMyPenance />} />
        </Routes>
      </BrowserRouter>
    </AuthenticationContextProvider>
  );
}
export default App;
