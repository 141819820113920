import React, { useState, useEffect, createContext } from "react";
import {
  checkForUser,
  loginRequest,
  loadConfessions,
  updatePenance,
  requestPasswordReset,
  resetPasswordLoggedOut,
  resetPassword,
} from "./authentication.service";

export const AuthenticationContext = createContext();

export const AuthenticationContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [confessions, setConfessions] = useState([]);
  const [error, setError] = useState(null);

  //when the component mounts, check if there is a user saved in localstorage
  useEffect(async () => {
    setIsLoading(true);
    try {
      const u = await checkForUser();
      if (u) {
        setUser(u);
        setIsAuthenticated(true);
        if (u.type === 1 || u.type === 2) {
          try {
            const c = await loadConfessions();
            setConfessions(c);
            setIsLoading(false);
          } catch (e) {
            setError(e);
            setIsLoading(false);
          }
        }
      }
    } catch (e) {
      setError(e);
    }
  }, []);

  const onLogin = (email, password) => {
    setIsLoading(true);
    loginRequest(email, password)
      .then(async (u) => {
        setUser(u);
        setError(null);
        if (u.type === 1 || u.type === 2) {
          try {
            const c = await loadConfessions();
            setConfessions(c);
            setIsLoading(false);
          } catch (e) {
            setError(e);
            setIsLoading(false);
          }
        }
        setIsAuthenticated(true);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  const onLogout = () => {
    localStorage.removeItem("jwtToken");
    setConfessions(null);
    setUser(null);
    setError(null);
    setIsAuthenticated(false);
  };

  const onUpdatePenance = (confessionId, penance) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      updatePenance(confessionId, penance)
        .then(async (res) => {
          try {
            const c = await loadConfessions();
            setConfessions(c);
            setIsLoading(false);
            resolve(c);
          } catch (e) {
            setError(e);
            setIsLoading(false);
            reject(e);
          }
        })
        .catch((e) => {
          setError(e);
          setIsLoading(false);
          reject(e);
        });
    });
  };

  const onRequestPasswordReset = (email) => {
    return new Promise(async (resolve, reject) => {
      requestPasswordReset(email).then(() => {
        setError("");
        resolve();
      }).catch((e) => {
        setError(e.message);
        reject(e);
      })
    })
  }

  const onResetPasswordLoggedOut = (email, resetCode, tempPassword, newPassword) => {
    return new Promise(async (resolve, reject) => {
      resetPasswordLoggedOut(email, resetCode, tempPassword, newPassword).then(() => resolve())
      .catch((e) => {
        reject(e)
      });
    });
  }

  const onResetPassword = (newPassword) => {
    return new Promise(async (resolve, reject) => {
      resetPassword(newPassword)
        .then(() => resolve())
        .catch((e) => reject(e));
    });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        confessions,
        error,
        onLogin,
        onLogout,
        onUpdatePenance,
        onRequestPasswordReset,
        onResetPasswordLoggedOut,
        onResetPassword,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
