import React, { useState, useContext } from "react";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import PulseLoader from "react-spinners/PulseLoader";

export const ConfessionResponseForm = ({
  message,
  id,
  setParentMessage,
  setParentId,
}) => {
  const { onUpdatePenance } = useContext(AuthenticationContext);
  const [penance, setPenance] = useState("");
  const [isSending, setIsSending] = useState(false);

  return (
    <form id="confessionResponseForm">
      <h2>Bless me, Father, for I have sinned:</h2>
      <p>{message}</p>
      <label htmlFor="penance">Penance</label>
      <textarea
        id="penance"
        name="penance"
        value={penance}
        onChange={(event) => {
          setPenance(event.target.value);
        }}
      />
      {!isSending ? (
        <button
          onClick={async (event) => {
            event.preventDefault();
            if (penance.length) {
              setIsSending(true);
              try {
                const c = await onUpdatePenance(id, penance);
                setPenance("");
                setParentMessage("none");
                setParentId("");
                setIsSending(false);
              } catch (e) {
                console.log(e);
                setIsSending(false);
              }
            }
          }}
        >
          Send Penance
        </button>
      ) : (
        <PulseLoader
          color={"#295927"}
          loading={isSending}
          size={20}
          speedMultiplier={0.5}
        />
      )}
    </form>
  );
};
