import React from "react";
import { Header } from "../header/Header.component";

export const Absolution = () => {
  return (
    <>
      <Header />
      <div id="confessionScreenContainer">
        <div id="confessionFormContainer">
          <div id="confessionForm">
            <h2>Your Confession is Complete</h2>
            <h3>
              Your priest has offered you these words of absolution, upon
              completion of your penance:
            </h3>
            <p>
              <i>
                God the Father of mercies, through the death and resurrection of
                his Son has reconciled the world to himself and sent the Holy
                Spirit among us for the forgiveness of sins; through the
                ministry of the Church may God give you pardon and peace, and I
                absolve you from your sins in the name of the Father, and of the
                Son and of the Holy Spirit. Amen. Go in peace to love and serve
                the Lord!
              </i>
            </p>
            <p>
              <small>You may now close this window.</small>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
