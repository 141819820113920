import React, { useContext, useState } from "react";
import { AuthenticationContext } from "../../../../services/authentication/authentication.context";
import { ViewConfessions } from "../confessions/ViewConfession.screen";
import { SettingsScreen } from "../settings/Settings.screen";

export const BishopDashboard = () => {
  const { user, onLogout } = useContext(AuthenticationContext);
  const [selected, setSelected] = useState(0);

  return (
    <div id="dashboard">
      <nav id="sideNav">
        <ul>
          <li
            className={selected === 0 ? "selected" : ""}
            onClick={() => setSelected(0)}
          >
            Reply to Confessions
          </li>
          <li
            className={selected === 1 ? "selected" : ""}
            onClick={() => setSelected(1)}
          >
            Settings
          </li>
          {/*
          <li
            className={selected === 2 ? "selected" : ""}
            onClick={() => setSelected(2)}
          >
            Manage Users
          </li> */}
        </ul>
      </nav>
      {(() => {
        switch (selected) {
          case 0:
            return <ViewConfessions setParentState={setSelected} />;
          case 1:
            return <SettingsScreen setParentState={setSelected} />;
        }
      })()}
      <button id="logout" onClick={onLogout}>
        Logout
      </button>
    </div>
  );
};
