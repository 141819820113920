import React, {useState, useContext} from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { AuthenticationContext } from '../../../services/authentication/authentication.context';
import { Header } from '../../header/Header.component';
import { Link } from 'react-router-dom';

export const PasswordResetScreen = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const resetCode = params.get("resetcode");

    const [email, setEmail] = useState("");
    const [temporaryPassword, setTemporaryPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState("");

    const [err, setErr] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [resetSucceeded, setResetSucceeded] = useState(false);

    const {error : serverError, onResetPasswordLoggedOut } = useContext(AuthenticationContext);

    const onSubmit = (event) => {
        event.preventDefault();
        setErr("");
        if(!validateFields()) return;
        setIsLoading(true);
        console.log(resetCode);
        onResetPasswordLoggedOut(email, resetCode, temporaryPassword, newPassword).then(() => {
          setIsLoading(false);
          setResetSucceeded(true);
        }).catch((e) => {
          setErr(e.message);
          setIsLoading(false);
        });
    }

    const validateFields = () => {
        if(!email.length) {
            setErr("Please enter an email address.");
            return false;
        }
        const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            email
        );
        if(!validEmail) {
            setErr("Please enter a valid email address.");
            return false;
        }
        if(!temporaryPassword.length) {
            setErr("Please enter the temporary password that was emailed to you.");
            return false;
        }
        if(!newPassword.length) {
            setErr("Please enter a new password.");
            return false;
        }
        if(!repeatedPassword.length) {
            setErr("Please enter the password a second time in the repeated password field.");
            return false;
        }
        if(newPassword !== repeatedPassword) {
            setErr("Please ensure that the passwords match.");
            return false;
        }
        return true;
    }

    return (
        <>
          <Header />
          <div className="authScreenContainer">
            <form className="authForm">
                <h3>Reset Password</h3>
                {!resetSucceeded ? (
                  <>
                    <label htmlFor="email">Email</label>
                    <br />
                    <input type="email" id="email" name="email" value={email} onChange={(e) => {
                        setEmail(e.target.value);
                    }} />
                    <br />
                    <br />
                    <label htmlFor="tempPassword">Enter temporary password</label>
                    <br />
                    <input type="password" id="tempPassword" name="tempPassword" value={temporaryPassword} onChange={(e) => {
                        setTemporaryPassword(e.target.value);
                    }} />
                    <br />
                    <br />
                    <label htmlFor="newPassword">Enter new password</label>
                    <br />
                    <input type="password" id="newPassword" name="newPassword" value={newPassword} onChange={(e) => {
                        setNewPassword(e.target.value);
                    }} />
                    <br />
                    <br />
                    <label htmlFor="repeatedPassword">Re-enter new password</label>
                    <br />
                    <input type="password" id="repeatedPassword" name="repeatedPassword" value={repeatedPassword} onChange={(e) => {
                        setRepeatedPassword(e.target.value);
                    }} />
                    <br />
                    <p style={{ fontStyle: "italic", color: "red" }}>
                      {serverError
                        ? "There was a problem sending the password reset email."
                        : err}
                    </p>
                    {!isLoading || serverError ? (
                      <button className="authButton authButtonSm" onClick={onSubmit}>
                        Reset Password
                      </button>
                    ) : (
                      <PulseLoader
                        color={"#295927"}
                        loading={isLoading}
                        size={10}
                        speedMultiplier={0.5}
                      />
                    )}
                  <br />
                  </>
                ) : (
                  <>
                  <p>Password successfully reset.</p>
                  </>
                )}
                <br />
                <Link to="/login" className='link'>Back to Login</Link>
            </form>
          </div>
        </>
    )
}