import React, { useState } from "react";
import {
  viewPenanceRequest,
  deleteConfession,
} from "../../services/confessions/confession.service";
import { ConfessionScreen } from "../confessions/screens/Confession.screen";
import { Absolution } from "./Absolution.component";
import { Header } from "../header/Header.component";

export const ViewMyPenance = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const penanceId = urlParams.get("cnfs");

  const [isVerified, setIsVerified] = useState(false);
  const [confessionDeleted, setConfessionDeleted] = useState(false);
  const [showConfessional, setShowConfessional] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [penance, setPenance] = useState("");
  const [id, setId] = useState("");

  if (!penanceId || showConfessional) {
    return <ConfessionScreen />;
  } else {
    if (!isVerified && !confessionDeleted) {
      return (
        <>
          <Header />
          <div id="loginScreenContainer">
            <form id="loginForm">
              <label htmlFor="password">Password:</label>
              <br />
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              <br />
              <p>{error}</p>
              <button
                id="loginButton"
                onClick={(event) => {
                  event.preventDefault();
                  if (!password.length) {
                    setError("Please enter your temporary password");
                  } else {
                    viewPenanceRequest(penanceId, password)
                      .then((res) => {
                        setPenance(res.data[0].penance);
                        setId(res.data[0].id);
                        setIsVerified(true);
                      })
                      .catch((e) =>
                        setError(
                          "Password not recognized or confession not found."
                        )
                      );
                  }
                }}
              >
                View Penance
              </button>
            </form>
          </div>
        </>
      );
    } else if (!confessionDeleted) {
      return (
        <>
          <Header />
          <div id="confessionScreenContainer">
            <div id="confessionFormContainer">
              <div id="confessionForm">
                <p>Thank you for your confession. Your penance is:</p>
                <p style={{ backgroundColor: "#bae0b8", padding: "20px" }}>
                  {penance}
                </p>
                <p>
                  Once you click the button below, your confession & penance
                  will be permanently deleted.
                </p>
                <button
                  id="loginButton"
                  onClick={(event) => {
                    event.preventDefault();
                    deleteConfession(id).then(() => {
                      setConfessionDeleted(true);
                      setPenance("");
                      setId("");
                    });
                  }}
                >
                  I will complete my penance and firmly intend to sin no more.
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <Absolution />;
    }
  }
};
